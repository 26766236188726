import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Button, Grid, Header } from 'semantic-ui-react';
import * as Sentry from "@sentry/react";

function ErrorFallback({ error }: { error: Error }): React.ReactElement {
  const { resetBoundary } = useErrorBoundary();

  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div role="alert">
      <Grid centered columns={2}>
        <Grid.Column>
          <Grid.Row>
            <Header>Something went wrong:</Header>
          </Grid.Row>
          <Grid.Row>
            <pre style={{ color: 'red' }}>{error.message}</pre>
          </Grid.Row>
          <Grid.Row>
            <Button onClick={resetBoundary}>Try again</Button>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default ErrorFallback;
