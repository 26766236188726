import Cookies from 'js-cookie';

interface ApiRequestOptions {
  url: string;
  method?: string;
  body?: any;
  anonymous?: boolean;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export default async function makeApiRequest({
  url,
  method = 'GET',
  body,
  anonymous = false,
  onSuccess,
  onError
}: ApiRequestOptions) {
  try {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    // Ajout du token seulement si non anonyme
    if (!anonymous) {
      const accessToken = Cookies.get('accessToken');
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    // Ajout du CSRF token pour les requêtes non GET
    if (method !== 'GET') {
      const csrfToken = Cookies.get('csrftoken');
      if (csrfToken) {
        headers['X-CSRFToken'] = csrfToken;
      }
    }

    const response = await fetch(url.startsWith('/api') ? url : `/api${url}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
      credentials: 'same-origin' // Important pour les cookies CSRF
    });

    const contentType = response.headers.get('content-type');
    const data = contentType?.includes('application/json') ? await response.json() : null;

    if (!response.ok) {
      throw new Error(data?.error || data?.detail || 'Une erreur est survenue');
    }

    onSuccess?.(data);
    return { data, error: null };

  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
    onError?.({ error: errorMessage });
    return { data: null, error: { message: errorMessage } };
  }
}
