import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import makeApiRequest from '@helpers/makeApiRequest';

export const RequestReset: React.FC = () => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');

    const { data, error } = await makeApiRequest({
      url: '/api/user/password/reset/',
      method: 'POST',
      body: { email },
      anonymous: true
    });

    if (error) {
      setStatus('error');
      setMessage(error.message);
      toast.error(error.message);
      return;
    }

    setStatus('success');
    setMessage(data.message);
    toast.success('Email de réinitialisation envoyé');
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <Form onSubmit={handleSubmit} error={status === 'error'}>
        <h2>Réinitialisation du mot de passe</h2>

        {status === 'success' ? (
          <Message positive>
            <Message.Header>Email envoyé</Message.Header>
            <p>{message}</p>
          </Message>
        ) : (
          <>
            <Form.Field>
              <label>Email</label>
              <Form.Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Votre email"
                required
                autoComplete="email"
              />
            </Form.Field>

            <Button
              type="submit"
              primary
              loading={status === 'loading'}
              disabled={status === 'loading'}
            >
              Réinitialiser le mot de passe
            </Button>

            {status === 'error' && (
              <Message error content={message} />
            )}
          </>
        )}
      </Form>
    </div>
  );
};
