import React, { createContext, useContext, useEffect, useState } from 'react';
import { type FeatureFlagContextType, type FeatureFlagProviderProps } from './FeatureFlagProvider.types';
import { type FeatureFlagType } from './FeatureFlagProvider.types';
import { useAuth } from '@context/AuthProvider';

const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFeatureFlags: (featureFlags: FeatureFlagType[]) => {},
});

const useFeatureFlag = (): FeatureFlagContextType => useContext(FeatureFlagContext);

const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps): React.ReactElement => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagType[]>([]);
  const { accessToken } = useAuth();

  useEffect(() => {
    const fetchFeatureFlags = async (): Promise<void> => {
      try {
        const res = await fetch('/api/featureflag/userfeatureflag/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const featureFlags = (await res.json()) as FeatureFlagType[];
        setFeatureFlags(featureFlags);
      } catch (error) {
        setFeatureFlags([]);
      }
    };

    if (accessToken) {
      void fetchFeatureFlags();
    }
  }, [accessToken]);

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags,
        setFeatureFlags,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagProvider, useFeatureFlag };
