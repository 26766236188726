import React from 'react';
import { Dimmer, Image, Loader, Header } from 'semantic-ui-react';

interface LoaderComponentProps {
    message?: string;
}

const LoaderComponent: React.FC<LoaderComponentProps> = ({ message }) => (
  <>
    <Dimmer active>
      <Loader />
      {message && <Header as="h2" style={{ color: 'white' }}>{message}</Header>}
    </Dimmer>
    <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
  </>
);

export default LoaderComponent;
