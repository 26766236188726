import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import makeApiRequest from '@helpers/makeApiRequest';

export const ResetConfirm: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    new_password: '',
    confirm_password: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.new_password !== formData.confirm_password) {
      setStatus('error');
      setMessage('Les mots de passe ne correspondent pas');
      return;
    }

    setStatus('loading');

    const { data, error } = await makeApiRequest({
      url: '/api/user/password/reset/confirm/',
      method: 'POST',
      body: {
        token,
        new_password: formData.new_password
      },
      anonymous: true
    });

    if (error) {
      setStatus('error');
      setMessage(error.message);
      toast.error(error.message);
      return;
    }

    setStatus('success');
    toast.success('Mot de passe réinitialisé avec succès');
    setTimeout(() => navigate('/login'), 3000);
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <Form onSubmit={handleSubmit} error={status === 'error'}>
        <h2>Nouveau mot de passe</h2>

        {status === 'success' ? (
          <Message positive>
            <Message.Header>Mot de passe réinitialisé</Message.Header>
            <p>Redirection vers la page de connexion...</p>
          </Message>
        ) : (
          <>
            <Form.Field>
              <label>Nouveau mot de passe</label>
              <Form.Input
                type="password"
                value={formData.new_password}
                onChange={(e) => setFormData({...formData, new_password: e.target.value})}
                required
                minLength={8}
              />
            </Form.Field>

            <Form.Field>
              <label>Confirmer le mot de passe</label>
              <Form.Input
                type="password"
                value={formData.confirm_password}
                onChange={(e) => setFormData({...formData, confirm_password: e.target.value})}
                required
              />
            </Form.Field>

            <Button
              type="submit"
              primary
              loading={status === 'loading'}
              disabled={status === 'loading'}
            >
              Valider
            </Button>

            {status === 'error' && (
              <Message error content={message} />
            )}
          </>
        )}
      </Form>
    </div>
  );
};
