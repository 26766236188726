import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { type BatchType, type EventType, type OrganizationType, type UserType } from '../types/Api.types';

type FetchNameByIdFunction = (id: number) => Promise<string>;

interface BreadcrumbItem {
  name: string;
  pathTo: string;
  isLast: boolean;
}

const fetchOrganizationNameById: FetchNameByIdFunction = async (id: number) => {
  const response = await fetch(`/api/user/organization/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const organization: OrganizationType = await response.json();
  return organization.name;
};

const fetchUserNameById: FetchNameByIdFunction = async (id: number) => {
  const response = await fetch(`/api/user/user/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const user: UserType = await response.json();
  return user.first_name + ' ' + user.last_name;
};

const fetchBatchNameById: FetchNameByIdFunction = async (id: number) => {
  const response = await fetch(`/api/batch/batch/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const batch: BatchType = await response.json();
  return batch.name;
};

const fetchEventNameById: FetchNameByIdFunction = async (id: number) => {
  const response = await fetch(`/api/rental/events/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const event: EventType = await response.json();
  return event.name;
};

const typeToApiFunction: Record<string, FetchNameByIdFunction> = {
  organizations: fetchOrganizationNameById,
  user: fetchUserNameById,
  batch: fetchBatchNameById,
  event: fetchEventNameById,
  cloth: fetchUserNameById,
};

const routeToName: Record<string, string> = {
  organizations: 'Organisations',
  inventory: 'Inventaire',
  type: 'Types d\'objets',
  user: 'Utilisateurs',
  invitetokens: 'Invitations',
  batch: 'Lots',
  login: 'Se connecter',
  check: 'Vérification',
  event: 'Événements',
  cloth: 'Tenues',
  new: 'Nouveau',
  changelog: 'Changelog',
  report: 'Rapport',
  availability: 'Disponibilité',
  'user-report': 'Rapport utilisateur',
  'password-reset-request': 'Demande de réinitialisation de mot de passe',
  'password-reset-confirm': 'Confirmation de réinitialisation de mot de passe',
};

const AutoBreadcrumb: React.FC = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const location = useLocation();

  const fetchBreadcrumbName = useCallback(async (value: string, typeName: string, index: number, array: string[]): Promise<BreadcrumbItem | null> => {
    let name = decodeURIComponent(value);
    const pathTo = `/${array.slice(0, index + 1).join('/')}`;
    const isLast = index === array.length - 1;

    if (!isNaN(Number(value)) && Object.keys(typeToApiFunction).includes(typeName)) {
      const apiFunc = typeToApiFunction[typeName];
      if (apiFunc) {
        name = await apiFunc(Number(value));
      }
    }

    if (value === 'password-reset-request') {
      name = 'Demande de réinitialisation de mot de passe';
    } else if (value === 'password-reset-confirm') {
      name = 'Confirmation de réinitialisation de mot de passe';
    } else if (Object.keys(routeToName).includes(value)) {
      name = routeToName[value];
    }

    if (array[index - 1] === 'password-reset-confirm' && index === array.length - 1) {
      return null;
    }

    return { name, pathTo, isLast };
  }, []);

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbPromises = pathnames.map(async (value, index, array) => {
      const typeName = index > 0 ? array[index - 1] : '';
      return await fetchBreadcrumbName(value, typeName, index, array);
    });

    void Promise.all(breadcrumbPromises).then((results) => {
      setBreadcrumbs(results.filter((item): item is BreadcrumbItem => item !== null));
    });
  }, [location, fetchBreadcrumbName]);

  return (
    <Breadcrumb>
      <Breadcrumb.Section link as={Link} to="/">
        Accueil
      </Breadcrumb.Section>
      {breadcrumbs.map(({ name, pathTo, isLast }, index) => (
        <React.Fragment key={pathTo}>
          {index >= 0 && <Breadcrumb.Divider icon="right chevron" />}
          <Breadcrumb.Section link={!isLast} as={!isLast ? Link : undefined} to={pathTo}>
            {name}
          </Breadcrumb.Section>
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default AutoBreadcrumb;
