import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

// eslint-disable-next-line no-undef
if (process.env.PUMPKIN_SENTRY_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://2d167db4b92f069399a8734790ddf2c3@o1296253.ingest.us.sentry.io/4507279740633088",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.feedbackIntegration({
        colorScheme: "system",
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/pumpkin\.crichard\.fr\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // eslint-disable-next-line no-undef
    environment: process.env.PUMPKIN_SENTRY_ENVIRONMENT,
    // eslint-disable-next-line no-undef
    release: process.env.PUMPKIN_VERSION,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
