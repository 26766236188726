import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Loader from '@components/Loader';

const InviteTokens = React.lazy(async () => await import('@pages/InviteTokens/InviteTokens'));
const InviteTokenDetail = React.lazy(async () => await import('@pages/InviteTokens/InviteTokenDetail'));
const UseInvite = React.lazy(async () => await import('@pages/InviteTokens/UseInvite'));

const InvitationTokensRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loader />}>
            <Outlet />
          </React.Suspense>
        }
      >
        <Route index element={<InviteTokens />} />
        <Route path="new" element={<InviteTokens />} />
        <Route
          path=":token"
          element={
            <React.Suspense fallback={<Loader />}>
              <Outlet />
            </React.Suspense>
          }
        >
          <Route path="reclaim" element={<UseInvite />} />
          <Route path="edit" element={<InviteTokenDetail />} />
          <Route index element={<InviteTokenDetail />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default InvitationTokensRoutes;
