import React from 'react';
import { Container, Icon, Image, Menu, Sidebar } from 'semantic-ui-react';
import { Media } from './AppMedia';
import { useAuth } from '../context/AuthProvider';

interface NavBarMobileProps {
  children: React.ReactNode;
  leftItems: React.ReactNode[];
  onPusherClick: () => void;
  onToggle: () => void;
  rightItems: React.ReactNode[];
  visible: boolean;
}

const NavBarMobile: React.FC<NavBarMobileProps> = (props) => {
  const { children, leftItems, onPusherClick, onToggle, rightItems, visible } = props;

  return (
    <Sidebar.Pushable>
      <Sidebar as={Menu} animation="overlay" icon="labeled" inverted items={leftItems} vertical visible={visible} />
      <Sidebar.Pusher dimmed={visible} onClick={onPusherClick}>
        <Menu fixed="top" inverted>
          <Menu.Item href="/"></Menu.Item>
          <Menu.Item onClick={onToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
          <Menu.Menu position="right">{rightItems}</Menu.Menu>
        </Menu>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

interface NavBarDesktopProps {
  leftItems: React.ReactNode[];
  rightItems: React.ReactNode[];
}

const NavBarDesktop: React.FC<NavBarDesktopProps> = (props) => {
  const { leftItems, rightItems } = props;

  return (
    <Menu fixed="top" inverted>
      <Menu.Item href="/">
        <Image size="mini" src="/static/logo_mini.png" />
      </Menu.Item>
      {leftItems}
      <Menu.Menu position="right">{rightItems}</Menu.Menu>
    </Menu>
  );
};

interface NavBarChildrenProps {
  children: React.ReactNode;
}

const NavBarChildren: React.FC<NavBarChildrenProps> = (props) => <Container
  style={{ marginTop: '5em' }}>{props.children}</Container>;

interface NavBarProps {
  children?: React.ReactNode;
}

const NavBar: React.FC<NavBarProps> = (props) => {
  const { children } = props;
  const { auth, userData, isSuperAdmin } = useAuth();
  const [visible, setVisible] = React.useState(false);

  const handlePusher = (): void => {
    if (visible) setVisible(false);
  };
  const handleToggle = (): void => {
    setVisible(!visible);
  };

  const userInitials = React.useMemo(() => {
    if (!userData || !auth) return '  ';
    const firtName = userData.first_name ?? ' ';
    const lastName = userData.last_name ?? ' ';
    return `${firtName.slice(0, 1)}${lastName.slice(0, 1)}`;
  }, [userData]);

  const items = {
    left: {
      auth: [
        <Menu.Item as="a" content="Organisations" icon="building" key="organizations" href="/organizations" />,
        <Menu.Item as="a" content="Utilisateurs" icon="user" key="users" href="/user" />,
        <Menu.Item as="a" content="Invitations" icon="pencil" key="invitetokens" href="/invitetokens" />,
      ],
      noAuth: [<Menu.Item as="a" content="Accueil" icon="home" key="home" href="/" />],
    },
    right: {
      auth: [
        <Menu.Item as="a" content={userInitials} icon="user" key="user" href={`/user/${userData?.id}`} />,
        isSuperAdmin && (<Menu.Item as="a" icon="cogs" key="admin" href={'/api/admin/'} />),
        <Menu.Item as="a" content="Déconnexion" icon="lock" key="logout" href="/user/logout/" />
      ],
      noAuth: [<Menu.Item as="a" content="Se connecter" icon="user" key="login" href="/user/login/" />],
    },
  };

  return (
    <div>
      <Media at="mobile">
        <NavBarMobile leftItems={auth ? items.left.auth : items.left.noAuth}
          rightItems={auth ? items.right.auth : items.right.noAuth} visible={visible}
          onPusherClick={handlePusher} onToggle={handleToggle}>
          <NavBarChildren>{children}</NavBarChildren>
        </NavBarMobile>
      </Media>
      <Media greaterThan="mobile">
        <NavBarDesktop leftItems={auth ? items.left.auth : items.left.noAuth}
          rightItems={auth ? items.right.auth : items.right.noAuth} />
        <NavBarChildren>{children}</NavBarChildren>
      </Media>
    </div>
  );
};

export { NavBar };
