import React, { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { NavBar } from '@components/NavBar';
import { BrowserRouter } from 'react-router-dom';
import { MediaContextProvider, mediaStyles } from '@components/AppMedia';
import { AuthProvider } from '@context/AuthProvider';
import { Container, Divider, Segment } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@components/ErrorFallback';
import AppRoutes from '@router/AppRoutes';
import LoaderComponent from '@components/Loader';
import AutoBreadcrumb from '@components/AutoBreadcrumb';
import { FeatureFlagProvider } from '@context/FeatureFlagProvider';

const Footer = React.lazy(async () => await import('@components/Footer'));

const App: React.FC = () => {
  return (
    <>
      <style>{mediaStyles}</style>
      <div className="site">
        <div className="content-wrap">
          <Suspense fallback={<LoaderComponent />}>
            <ToastContainer />
            <AuthProvider>
              <FeatureFlagProvider>
                <MediaContextProvider>
                  <Container>
                    <NavBar />
                  </Container>
                  <Container style={{ width: '98vw' }}>
                    <Segment>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <BrowserRouter>
                          <AutoBreadcrumb />
                          <Divider />
                          <AppRoutes />
                        </BrowserRouter>
                      </ErrorBoundary>
                    </Segment>
                  </Container>
                </MediaContextProvider>
              </FeatureFlagProvider>
            </AuthProvider>
          </Suspense>
        </div>
        <Container className="footer" fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
};
export default App;
