import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from 'react-error-boundary';
import OrganizationsRoutes from './OrganizationsRoutes';
import InvitationTokensRoutes from './InvitationTokensRoutes';
import { ResetConfirm } from '@pages/PasswordReset/ResetConfirm';
import { RequestReset } from '@pages/PasswordReset/RequestReset';

const Home = lazy(() => import('@pages/Home'));
const Users = lazy(() => import('@pages/User/Users'));
const Login = lazy(() => import('@pages/Login'));
const Logout = lazy(() => import('@components/Logout'));
const ErrorFallback = lazy(() => import('@components/ErrorFallback'));
const NotFound = lazy(() => import('@pages/NotFound'));
const UserDetail = lazy(() => import('@pages/User/UserDetail'));
const ChangeLog = lazy(() => import('@components/ChangeLog'));
const LoaderComponent = lazy(() => import('@components/Loader'));

const AppRoutes: React.FC = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoaderComponent />}>
        <SentryRoutes>
          <Route
            path="/"
            element={
              <React.Suspense>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="/user"
            element={
              <React.Suspense>
                <Users />
              </React.Suspense>
            }
          />
          <Route
            path="/user/:id"
            element={
              <React.Suspense>
                <UserDetail />
              </React.Suspense>
            }
          />
          <Route path="/organizations/*" element={<OrganizationsRoutes />} />
          <Route path="/invitetokens/*" element={<InvitationTokensRoutes />} />
          <Route
            path="/user/login/"
            element={
              <React.Suspense>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="/user/logout/"
            element={
              <React.Suspense>
                <Logout />
              </React.Suspense>
            }
          />
          <Route
            path="/changelog"
            element={
              <React.Suspense>
                <ChangeLog />
              </React.Suspense>
            }
          />
          <Route
            path="/password-reset-request/"
            element={
              <React.Suspense>
                <RequestReset />
              </React.Suspense>
            }
          />
          <Route
            path="/password-reset-confirm/:token"
            element={
              <React.Suspense>
                <ResetConfirm />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense>
                <NotFound />
              </React.Suspense>
            }
          />
        </SentryRoutes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppRoutes;
