import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import * as Sentry from "@sentry/react";
import { type AuthContextType, type AuthProviderProps } from './AuthProvider.types';
import { type UserRoleType, type UserType } from '../types/Api.types';

const AuthContext = createContext<AuthContextType>({
  auth: false,
  isSuperAdmin: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsSuperAdmin: (isSuperAdmin) => {
  },
  organizationRoles: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setOrganizationRoles: (roles) => {
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAuth: (auth) => {
  },
  userData: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserData: (userData) => {
  },
  accessToken: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAccessToken: (accessToken) => {
  },
});

const useAuth = (): AuthContextType => useContext(AuthContext);

const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement => {
  const [auth, setAuth] = useState(!!Cookies.get('accessToken'));
  const [accessToken, setAccessToken] = useState<string | null>(Cookies.get('accessToken') ?? null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [organizationRoles, setOrganizationRoles] = useState<UserRoleType[]>([]);
  const [userData, setUserData] = useState<Pick<UserType, 'id' | 'first_name' | 'last_name' | 'email' | 'glove_size' | 'multi_pockets_size' | 'parka_size' | 'helmet_size' | 'shirt_size' | 'pullover_size' | 'soft_shell_size' | 'pants_size' | 'shoe_size'> | null>(null);

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      try {
        const res = await fetch('/api/user/me/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const user = (await res.json()) as UserType;
        setIsSuperAdmin(user.is_superuser);
        setOrganizationRoles(user.userroles);
        setUserData({
          id: Number(user.id),
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          helmet_size: user.helmet_size,
          shirt_size: user.shirt_size,
          pullover_size: user.pullover_size,
          multi_pockets_size: user.multi_pockets_size,
          parka_size: user.parka_size,
          soft_shell_size: user.soft_shell_size,
          pants_size: user.pants_size,
          shoe_size: user.shoe_size,
          glove_size: user.glove_size,
        });
        Sentry.setUser({
          id: String(user.id),
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
        });

      } catch (error) {
        setIsSuperAdmin(false);
        setOrganizationRoles([]);
        setUserData(null);
        Sentry.setUser(null);
      }
    };

    if (auth) {
      void fetchUser();
    }
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        isSuperAdmin,
        setIsSuperAdmin,
        organizationRoles,
        setOrganizationRoles,
        userData,
        setUserData,
        accessToken,
        setAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
