import React from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import './Soon.css';

const Soon: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Segment placeholder className="segmentText">
      <Header icon className="constructionText">
        <Icon name="clock outline" />
                Travaux en cours
      </Header>
      <p>Cette section est en cours de développement et sera disponible prochainement.</p>
      <Button primary onClick={() => navigate('/')}>
                Retour à l'accueil
      </Button>
    </Segment>
  );
};

export default Soon;
