import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Loader from '@components/Loader';
import Soon from '@components/Soon';

const BatchRoutes = React.lazy(async () => await import('@router/BatchRoutes'));
const EventsRoutes = React.lazy(async () => await import('@router/EventsRoutes'));
const ClothRoutes = React.lazy(async () => await import('@router/ClothRoutes'));
const Organizations = React.lazy(async () => await import('@pages/Organizations/Organizations'));
const OrganizationDetail = React.lazy(async () => await import('@pages/Organizations/OrganizationDetail'));
const Inventory = React.lazy(async () => await import('@pages/Inventory/Inventory'));
const ItemType = React.lazy(async () => await import('@pages/ItemType/ItemType'));

const OrganizationsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loader />}>
            <Outlet />
          </React.Suspense>
        }
      >
        <Route index element={<Organizations />} />
        <Route path="new" element={<Soon />} />
        <Route
          path=":orgId"
          element={
            <React.Suspense fallback={<Loader />}>
              <Outlet />
            </React.Suspense>
          }
        >
          <Route index element={<OrganizationDetail />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="type" element={<ItemType />} />
          <Route path="batch/*" element={<BatchRoutes />} />
          <Route path="event/*" element={<EventsRoutes />} />
          <Route path="cloth/*" element={<ClothRoutes />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default OrganizationsRoutes;
